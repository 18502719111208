import * as React from "react"
import '../styles/global.scss';
import FullWidthContent from "../components/FullWidthContent/FullWidthContent";
import Contact from "../components/Contact/Contact";
import Layout from "../components/Layout/Layout";
import {graphql} from "gatsby";
import {useTranslation} from "react-i18next";

const IndexPage = () => {
    const {t} = useTranslation();
    return (

        <Layout isHomePage={true}>
            {/*<Features/>*/}
            <section id="features">
                <FullWidthContent darkMode={false} title={t('autoposition.title')}
                                  featuredWords={[1, 2]}
                                  signInButton={true}
                                  canvasMode={'autoposition'}>
                    <p>{t('autoposition.content')}</p>

                </FullWidthContent>
                <FullWidthContent darkMode={true} title={t('customSize.title')}
                                  alightRight={true}
                                  featuredWords={[1]}
                                  signInButton={true}
                                  canvasMode={'resize'}>

                    <p>{t('customSize.content')}</p>

                </FullWidthContent>
                <FullWidthContent darkMode={false} title={t('rotating.title')} alightRight={false}
                                  featuredWords={[3]}
                                  signInButton={true}
                                  canvasMode={'rotation'}>

                    <p>{t('rotating.content')}</p>

                    <p></p>

                </FullWidthContent>
                <FullWidthContent darkMode={true} title={t('autostocking.title')} alightRight={true}
                                  featuredWords={[1]}
                                  signInButton={true}
                                  canvasMode={'autostocking'}>

                    <p>{t('autostocking.content')}</p>

                </FullWidthContent>
            </section>
            <Contact/>
        </Layout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
